import {useLocalePath} from '#i18n';
import {useGlobalSearchQuery, type Bericht, type Werkveld, GlobalSearchQueryVariables} from '~/graphql/graphql';

const getRouteTo = (result: Bericht | Werkveld): string => {
  const localePath = useLocalePath();

  if (result.__typename === 'Werkveld') {
    return localePath({
      name: result.parent ? 'ambitions-ambition-slug' : 'ambitions-ambition',
      params: result.parent ? {ambition: result.parent.slug, slug: result.slug} : {ambition: result.slug},
    });
  }

  return localePath({name: 'news-slug', params: {slug: result.slug}});
};

export const useSearch = (variables: GlobalSearchQueryVariables) => {
  const {result, refetch: searchRefetch} = useGlobalSearchQuery(variables, {
    enabled: computed(() => !!variables.query),
  });

  return {
    searchResults: computed(() => result?.value?.globalSearch),
    searchRefetch,
    getRouteTo,
  };
};
